//
//
//
//
//
//
//
//
//
//

import remoteLoad from '@/common/remoteLoad'
export default {
  created: async function () {
    this._data.x = this.$route.query.X
    this._data.y = this.$route.query.Y
    this._data.n = this.$route.query.N
    // this._data.src = 'http://apis.map.qq.com/tools/routeplan/eword=' + this._data.n + '&epointx=' + this._data.x + '&epointy=' + this._data.y + '?referer=myapp&key=OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77'   
    
  },
  async mounted() {
    let _this = this;
    window.onresize = function () {
      _this.resizeMap();
    }
    // 已载入高德地图API，则直接初始化地图
    if (window.AMap && window.AMapUI) {
      this.initMap()
      // 未载入高德地图API，则先载入API再初始化
    } else {
      // 2bb900ee621fcd705cf4ca3d526754f5
      await remoteLoad('https://webapi.amap.com/maps?v=1.4.6&key=7c7b892fe70103211de5180dc1715010')
      await remoteLoad('https://webapi.amap.com/ui/1.0/main.js?v=1.0.11')
      this.initMap()
    }     
  },
  methods: {
    returnBack: function () {
      this.$router.go(-1)
    },
    initMap() {
      var _this = this;
      this.resizeMap();
      // 加载PositionPicker，loadUI的路径参数为模块名中 'ui/' 之后的部分
      let mapConfig = {
        zoom: 16
      }
      let position = [this._data.x, this._data.y]
      mapConfig.center = position
      let map = new AMap.Map('shopPosition', mapConfig)
      AMapUI.loadUI(['control/BasicControl'], function(BasicControl) {

          //添加一个缩放控件
          map.addControl(new BasicControl.Zoom({
              position: 'rb'
          }));
      });
      AMapUI.loadUI(['overlay/SimpleMarker'], function(SimpleMarker) {

          var iconTheme = 'default';

          //内置的样式
          var iconStyles = SimpleMarker.getBuiltInIconStyles(iconTheme);

          // var lngLats = [];
          new SimpleMarker({
              iconTheme: iconTheme,
              //使用内置的iconStyle
              iconStyle: iconStyles[18],

              //图标文字
              iconLabel: {
                  //A,B,C.....
                  innerHTML: '',
                  style: {
                      //颜色, #333, red等等，这里仅作示例，取iconStyle中首尾相对的颜色
                      color: ''
                  }
              },

              //显示定位点
              showPositionPoint:true,

              map: map,
              position: mapConfig.center,

              //Marker的label(见http://lbs.amap.com/api/javascript-api/reference/overlay/#Marker)
              label: {
                  content: _this._data.n,
                  offset: new AMap.Pixel(27, 25)
              }
          });          

      });             
    },
    resizeMap() {
      let wiHeight = document.documentElement.clientHeight || document.body.clientHeight;
      this.mapStyle = {
        'height': wiHeight-44 + 'px'
      }
    }         
  },
  data () {
    return {
      src: null,
      mapStyle: {}
    }
  }
}

